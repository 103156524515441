@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@500&display=swap');

* {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 300;
}

body::-webkit-scrollbar {
  width: 0.75em;
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(5, 6, 15, 0.2);
  outline: 1px solid rgba(5, 6, 15, 0.25);
}

.scrollbar::-webkit-scrollbar {
  width: 0.3em;
  background: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 50rem;
}

.link-none:hover {
  color: inherit;
  text-decoration: none;
}

.link-none-white:hover {
  color: #fff;
  text-decoration: none;
}

.title {
  text-transform: uppercase;
  font-family: 'Fira Sans Condensed', sans-serif;
  text-align: center;
  margin-bottom: 0;
}

body,
#wrapper {
  min-height: 100vh;
}

main {
  flex: 1;
}

.grow,
.grow-big,
.grow-small {
  transition: all 0.2s ease;
}

.grow-small:hover {
  transform: scale(1.025);
}

.grow:hover {
  transform: scale(1.1);
}

.grow-big:hover {
  transform: scale(1.3);
}

.ad-img {
  object-fit: contain;
  height: 170px;
}

.image-gallery-slides {
  background: black;
}

.adscreen-image .image-gallery-content.bottom .image-gallery-image {
  max-width: 100%;
  height: 525px;
}
.adscreen-image .image-gallery-content.bottom.fullscreen .image-gallery-image {
  max-width: 100%;
  height: 100vh;
}

.adscreen-image .image-gallery-thumbnail .image-gallery-thumbnail-image {
  max-height: 80px;
  object-fit: contain;
}

@media (max-width: 1023px) {
  .adscreen-image .image-gallery-content.bottom .image-gallery-image {
    max-width: 100%;
    height: 350px;
  }
  .adscreen-image
    .image-gallery-content.bottom.fullscreen
    .image-gallery-image {
    max-width: 100%;
    height: 90vh;
  }
}

@media (min-width: 1023px) {
  .mobile {
    display: none !important;
  }

  .select-city {
    width: 17rem;
  }
}

@media (max-width: 1023px) {
  .web {
    display: none !important;
  }

  .select-city {
    width: 13rem;
  }
}

.css-26l3qy-menu {
  z-index: 9999 !important;
}

.image-gallery-thumbnails-wrapper {
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
}

.bg-gradient-alt {
  background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

.bg-gradient-danger {
  background: #ed213a;
  background: -webkit-linear-gradient(to right, #93291e, #ed213a);
  background: linear-gradient(to right, #93291e, #ed213a);
}

.bg-tilted-lines {
  background: repeating-linear-gradient(
    45deg,
    #fafafa,
    #fafafa 10px,
    rgba(5, 6, 15, 0.06) 0,
    rgba(5, 6, 15, 0.06) 14px
  );
}

.bg-subcategory-tile {
  background-color: #e0f8ff;
  border: 1px #e0d8ff solid;
}

.web-banner {
  background-image: linear-gradient(
      30deg,
      #ffffff 12%,
      transparent 12.5%,
      transparent 87%,
      #ffffff 87.5%,
      #ffffff
    ),
    linear-gradient(
      150deg,
      #ffffff 12%,
      transparent 12.5%,
      transparent 87%,
      #ffffff 87.5%,
      #ffffff
    ),
    linear-gradient(
      30deg,
      #ffffff 12%,
      transparent 12.5%,
      transparent 87%,
      #ffffff 87.5%,
      #ffffff
    ),
    linear-gradient(
      150deg,
      #ffffff 12%,
      transparent 12.5%,
      transparent 87%,
      #ffffff 87.5%,
      #ffffff
    ),
    linear-gradient(
      60deg,
      rgba(255, 255, 255, 0.5) 25%,
      transparent 25.5%,
      transparent 75%,
      rgba(255, 255, 255, 0.5) 75%,
      rgba(255, 255, 255, 0.5)
    ),
    linear-gradient(
      60deg,
      rgba(255, 255, 255, 0.5) 25%,
      transparent 25.5%,
      transparent 75%,
      rgba(255, 255, 255, 0.5) 75%,
      rgba(255, 255, 255, 0.5)
    );
  background-size: 64px 112px;
  background-position: 0 0, 0 0, 32px 56px, 32px 56px, 0 0, 32px 56px;
  background-color: #b8daff;
}
